:root {
  --green-color: #85c59d;
  --bg-dark: #222;
}

.container-box {
  padding-left: 4%;
  padding-right: 4%;
}

.text-red {
  color: #fe4a55 !important;
}

.text-green {
  color: #c0e4ef !important;
}
.text-slik {
  color: #4c91b4 !important;
}
.contact-email a:hover {
  color: #4c91b4;
}
.top-heading {
  text-align: center;
  width: 55%;
  margin: 0 auto;
}

.top-heading h2 {
  font-size: 50px;
  font-weight: bold;
  color: var(--bg-dark);
}

.head-2 {
  font-size: 50px;
  font-weight: bold;
  color: var(--bg-dark);
}

.top-heading h5 {
  font-size: 22px;
  color: #606060;
}

.paragraph {
  font-size: 17.4px;
  color: #333;
}

.bg-bg-dark {
  background-color: var(--bg-dark);
}

.bg-green {
  background-color: #1a5070;
}

.bg-light-blue {
  background-color: #e9eff8 !important;
}

.button {
  padding: 12px 30px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.button:hover {
  background-color: var(--bg-dark) !important;
  color: #fff !important;
}

.category-btn {
  /* box-shadow: #9cb5d9 0 -8px 6px inset; */
  background-color: #fff;
  color: #252161;
}

.category-btn:active {
  transform: scale(1.025) !important;
}

.active-category {
  background-color: #252161;
  color: #fff;
}

/* page header */
.page-header {
  /* background-color: var(--green-color); */
  background-color: #4c91b4;
}

.page-header > div > div {
  margin-top: 50px;
}

.header-path-link {
  font-size: 20px;
  font-weight: 600;
}

.loader {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
@-webkit-keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.ball-pulse-sync > div:nth-child(1) {
  -webkit-animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
}

.ball-pulse-sync {
  position: absolute;
  top: 50%;
  left: 50%;
}

.ball-pulse-sync > div:nth-child(2) {
  -webkit-animation: ball-pulse-sync 0.6s -0.07s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -0.07s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(3) {
  -webkit-animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
}

.ball-pulse-sync > div {
  background-color: #1a5070;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}
.form-rollz:focus {
  outline: 1px solid #1a5070;
}
