:root {
  --green-color: #85c59d;
  --dark-blue: #252161;
}

.feature {
  padding: 15px;
}

.feature i {
  font-size: 40px;
  color: var(--dark-blue);
}

.feature h5 {
  font-size: 22px;
  font-weight: 600;
  padding: 6px 0;
}

.blog-title{
  font-size: 40px;
  font-weight: bold;
  color: var(--bg-dark);
}
.post-recent {
  border-radius: 10px;
  width: 150px;
  height: 80px;
}
.sidebar-text {
  padding-left: 20px;
}
.sidebar-widget {
  display: flex;
  margin-bottom: 20px;
}
.sidebar-thumb {
  width: 40%;
}
.post-title {
  color: #000;
  font-size: 16px;
  text-decoration: none;
}
.sidebar-widget-title{
  margin-bottom: 30px;
}
/* contact us */

.contact-form label {
  font-size: 18px;
}

.contact-field {
  padding: 13px 20px;
  display: block;
  width: 100%;
  margin-top: 6px;
  font-size: 17px;
  outline: none;
  border: 0;
  background-color: #f4f5f5;
  color: #222;
  border-radius: 3px;
}

.contact-formn{
  padding: 13px 20px;
  display: block;
  width: 100%;
  margin-top: 6px;
  font-size: 17px;
  outline: none;
  border: 0;
  background-color: #fff;
  color: #222;
  border-radius: 3px;
}
.contact-field:focus {
  box-shadow: 1px 1px 20px #25216137 inset;
}

/* blogs */
.arrow-animation {
  display: inline-block;
  animation: slideAni 1s ease-in-out infinite;
}

@keyframes slideAni {
  0%,
  100% {
    transform: translate(0, 0);
    opacity: 0.1;
  }

  50% {
    transform: translate(10px, 0);
    opacity: 1;
  }
}

.service-card {
  min-height: 460px;
  text-align: center;
  box-shadow: 1px 1px 10px #33333316;
  transition: all 0.4s;
}

.service-card p {
  font-size: 18px;
  margin-bottom: 0;
}

.service-card h2 {
  margin: auto;
}

.service-card:hover {
  background-color: #2521611e !important;
}

/* Teams members */
.team-bg {
  height: 300px;
  background-color: #85c59d;
  margin: 100px 0 !important;
}

.team-card {
  background-color: #fff;
  text-align: center;
  padding: 30px;
  min-height: 500px;
  transform: translateY(-100px);
  box-shadow: 1px 1px 10px #33333363;
  transition: 0.4s;
}

.team-card:hover {
  transform: translateY(-80px);
}

.team-card > img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top left;
  box-shadow: 1px 1px 5px #33333390;
}

.team-content > h5 {
  font-weight: bold;
  font-size: 25px;
  color: #252161;
}

.team-content > p {
  font-size: 20px;
  color: #85c59d;
}

.team-contact {
  background-color: #25216148;
  padding: 15px 30px;
}

.team-contact i {
  font-size: 25px;
  color: #252161;
}
.team-contact h5 {
  color: #252161;
  font-size: 19px;
}

.about-category-image {
  width: 150px;
  object-fit: cover;
}
.social-icons {
  display: flex;
  justify-content: center;
}
.social-icons a {
  display: inline-block;
  width: 70px;
  height: 70px;
  display: grid;
  place-items: center;
  font-size: 30px;
  background-color: #fff;
  margin: 0 10px;
  border-radius: 50%;
}
.certificate-img {
  text-align: center;
}
.img-all {
  border: 1px solid #000;
}
.exec-padding {
  padding-bottom: 20px;
}
.iso-certi {
  font-weight: bold;
  color: var(--bg-dark);
}
.w-90 {
  width: 90%;
}
.ms-10 {
  margin-left: 6%;
}
.inquiry-back {
  background-color: #f8f9fa;
}
.cta-btn {
  padding-top: 14%;
}
.cta-section {
  background-image: linear-gradient(#00000044, #00000044),
    url("../Images/cta.jpg");
  background-size: cover;
  background-attachment: fixed;
}
.bg-gray {
  background-color: #f8f9fa;
}
.certi-img {
  padding: 20px;
}
.certi-img img{
  border: 1px solid #000;
}