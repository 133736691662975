:root {
  --green-color: #85c59d;
  --bg-dark: #222;
}
.exim-club {
  line-height: 1.5 !important;
}
.logo {
  width: 200px;
  object-fit: contain;
  font-weight: 800;
  text-decoration: none;
  color: #fff;
  letter-spacing: 4px;
  border-bottom: 4px solid #c0e4ef;
}
.btn-modal-close {
  border: none;
  background-color: transparent;
}
.navibar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  transition: all 0.5s;
  height: 90px;
  z-index: 999;
}

.white-navbar {
  background-color: #fff;
  animation: fadedownNav 0.8s ease-in-out 0s 1 normal none running;
  box-shadow: 2px 2px 10px #33333350;
}

.white-navbar .logo,
.white-navbar .navlinks li a,
.white-navbar .navlinks li a .badge {
  color: #222;
}

@keyframes fadedownNav {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.navbar-links {
  background-color: var(--bg-dark);
}

.navlinks {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
}

.navlinks > li > a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 15px;
  display: block;
  margin: 0 12px;
  padding: 20px 0;
}

.navlinks > li > a:hover {
  color: #c0e4ef;
}

.inner-links {
  position: absolute;
  top: 80%;
  min-width: 200px;
  background-color: #fff;
  color: white;
  list-style: none;
  display: none;
  padding-left: 0;
  z-index: 2;
  animation: innerLinksAni 0.3s ease-in-out 0s 1 normal none running;
  box-shadow: 1px 1px 10px #11111152;
}

.inner-links > li a {
  color: #222;
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  border-top: 0.5px solid #ffffff67;
  text-transform: uppercase;
  font-size: 15px;
  border-bottom: 1px solid #8888881c;
  position: relative;
}

.inner-links > li a:hover {
  background-color: #c0e4ef;
}

.navlinks > li:hover .inner-links {
  display: block;
}

.inner-inner-links {
  position: absolute;
  top: 10%;
  right: 100%;
  min-width: 240px;
  background-color: #fff;
  color: white;
  list-style: none;
  display: none;
  padding-left: 0;
  z-index: 2;
  animation: innerLinksAni 0.3s ease-in-out 0s 1 normal none running;
  box-shadow: 1px 1px 10px #11111152;
}

.inner-links > li:hover .inner-inner-links {
  display: block;
}

.bi-caret-up-fill {
  position: absolute;
  color: #fff;
  top: -19px;
  left: 10%;
  font-size: 20px;
}
.banner-section {
  z-index: 1;
}

.bd-slide-bg {
  width: 100% !important;
  position: absolute;
  left: 0;
  z-index: -1;
  height: 100%;
  overflow: hidden;
}
.mail-second {
  margin-left: 30px;
}
.header-banner {
  min-height: 100vh;
  background-size: cover;
}
.banner-img {
  height: 77vh;
  object-fit: contain;
  object-position: 51% 4%;
}
.banner-section {
  overflow: hidden;
  margin-top: 82px;
}

.banner-content {
  color: #fff;
  padding: 40px;
  padding-bottom: 60px;
  text-align: end;
  z-index: 10;
}

.banner-content h1 {
  font-size: 62px;
  font-weight: 700;
}

.banner-content h5 {
  font-size: 24px;
  font-weight: 600;
  color: #222;
}

.banner-content p {
  font-size: 20px;
  font-weight: 500;
}

.banner-content a {
  padding: 15px 35px;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  background-color: #fff;
  color: #222;
  text-transform: uppercase;
}

.banner-content a:hover {
  background-color: #4c91b4;
  color: #fff;
}
.about-section img {
  height: 385px;
  object-fit: cover;
}

.about-section h2 {
  font-size: 50px;
  font-weight: bold;
  color: var(--bg-dark);
}

.about-overlay-image {
  position: absolute;
  top: -18%;
  left: 4%;
  height: 480px !important;
}

.about-card h5 {
  color: #222;
  font-weight: 600;
  font-size: 25px;
}
.testimonials-card {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding: 40px 30px;
  background-color: #fff;
}

.testimonials-card img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonials-card h5 {
  font-weight: bold;
  color: #222;
  font-size: 22px;
}

.rating-stars {
  color: goldenrod;
  font-size: 22px;
}
.product-card {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  padding: 20px 10px;
  min-height: 320px;
  border: 1px solid #99999961;
}
.product-card:hover img {
  transform: scale(1.1);
}
.product-card img {
  height: 203px;
  margin-bottom: 12px;
  /* object-fit: cover; */
  transition: 0.4s;
}

.product-card h5 {
  font-size: 17px;
}
.blog-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  transition: 0.4s;
}

.blog-card:hover {
  transform: scale(0.96);
}

.blog-card img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.blog-content a {
  font-size: 20px;
  text-decoration: none;
  color: #4c91b4;
}

.blog-content a:hover {
  color: #222;
}
.read-more-btn {
  display: inline-block;
  padding: 15px;

  border-radius: 50px;
  background-color: var(--green-color);
  color: #fff;
  width: 100%;
}

.read-more-btn span {
  font-size: 16px;
}

.slider-btns button {
  padding: 10px 20px;
  border: none;
  font-size: 26px;
  background-color: #fff;
  color: var(--bg-dark);
}

.slider-btns button:hover {
  color: #4c91b4;
}

.slider-btns button:active {
  transform: scale(1.04);
}
.footer {
  background-color: #1a5070;
}

.footer-links a {
  display: block;
  padding: 2px 0;
  color: #fff;
  transition: 0.4s;
  margin-bottom: 8px;
  text-decoration: none;
  font-size: 16.5px;
}

.footer-links a:hover {
  color: #c0e4ef;
}

.social-links {
  display: flex;
}

.social-links a {
  padding: 5px 9px;
  margin-right: 10px;
  color: #fff;
  font-size: 20px;
}

.social-links a:hover {
  color: #c0e4ef;
}

.policy-terms a:hover {
  color: #c0e4ef !important;
}
.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.medical-card {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  padding: 20px 10px;
  border: 1px solid #99999961;
  display: flex;
  align-items: center;
  justify-content: center;
}
.medical-image {
  height: 300px;
  object-fit: contain;
}
.medical-card:hover img {
  transform: scale(1.1);
}

.pagination span {
  padding: 10px;
  cursor: pointer;
}
.text-new {
  color: #4c91b4;
}
.text-old {
  color: #1a5070;
}
.bg-new {
  background-color: #4c91b4;
}
.mobile-menu {
  display: none;
}
.dispalay-n {
  display: none;
}
.blogcomment {
  margin-bottom: 20px;
}
.comment-box {
  background-color: #fff;
  padding: 25px;
  border-radius: var(--bs-border-radius) !important;
}
#rejveer-mobile {
  display: none;
}
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
}

.pagination a {
  padding: 8px 12px;
  border: 1px solid #ccc;
  color: #4c91b4;
  text-decoration: none;
  cursor: pointer;
}

.pagination a:hover {
  background-color: #f0f0f0;
}

.pagination .active a {
  background-color: #4c91b4;
  color: #fff;
  border-color: #4c91b4;
}

.pagination .disabled a {
  pointer-events: none;
  color: #aaa;
  border-color: #ccc;
}
.product-inquiry {
  display: none;
}
.text-category {
  color: #000;
  text-decoration: none;
  display: inherit;
}
.text-product {
  color: #4c91b4 !important;
  width: 100% !important;
  background: #f8f9fa;
}
.contact-box {
  padding: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  height: 105%;
}
.home-banner {
  height: 501px;
}
@media screen and (max-width: 768px) {
  .header-banner {
    min-height: 55vh;
  }
  .banner-content h1 {
    font-size: 35px;
  }
  .banner-content {
    color: #fff;
    padding: 15px;
    padding-bottom: 0px;
    text-align: left;
    z-index: 10;
    padding-top: 28%;
  }
  .about-overlay-image {
    display: none;
  }
  .about-card {
    padding-top: 4%;
  }
  .top-heading h2 {
    font-size: 31px;
  }
  .top-heading {
    width: 90%;
  }
  .certi-img .w-90 {
    width: 100%;
  }
  .cta-btn {
    padding-top: 4% !important;
  }
  .py-4.border-top.border-secondary {
    flex-direction: column;
  }
  .policy-terms {
    padding-top: 10px;
  }
  .footer-link {
    padding-bottom: 0px !important;
  }
  .dispalay-n {
    display: block !important;
  }
  .cta-section {
    background-attachment: initial !important;
  }
  #bg-productCare {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem !important;
  }
  .about-category-image {
    width: 75% !important;
    padding-bottom: 10%;
  }
  #bd-productContent {
    margin-left: 0rem !important;
  }
  #title-web {
    padding-top: 15%;
    padding-bottom: 15%;
  }
  .inquiry-back {
    padding-bottom: 3rem;
  }
  .aboutInquiry {
    padding-bottom: 3rem;
  }
  #direction-flex {
    flex-direction: column;
  }
  #contct-us {
    margin-left: 0rem !important;
  }
  .certificate-img div {
    margin-bottom: 1.5rem;
  }
  #bussiness-section img {
    padding-top: 5% !important;
    width: 100%;
  }
  .bussiess-doller {
    padding-top: 15%;
    padding-bottom: 15%;
  }
  #certificate {
    padding-top: 10%;
    text-align: center !important;
  }
  .exim-club {
    padding-bottom: 12px;
    line-height: 1.5 !important;
  }
  .input-field {
    flex-direction: column;
  }
  .bg-client {
    padding-bottom: 0rem !important;
  }
  #main-product {
    flex-direction: column;
    padding-bottom: 12%;
  }
  #product-image {
    padding-bottom: 5%;
  }
  .product {
    margin-bottom: 1rem !important;
  }
  #filter-product {
    padding-top: 12%;
  }
  .pagination {
    flex-wrap: wrap;
  }
  .product-main-img img {
    width: 100%;
  }
  .mobile-menu {
    display: block;
    position: fixed;
    background-color: #4c91b4;
    width: 75%;
    height: 100%;
    top: 0;
    left: -100%;
    z-index: 999999999;
    box-shadow: 2px 2px 40px #22222273;
    transition: all 0.4s;
    overflow: auto;
    scrollbar-width: none;
  }

  .mobile-menu li {
    list-style: none !important;
  }
  .showMenu {
    left: 0;
  }
  .menu-btn {
    display: inline;
  }
  .mobile-navlinks li a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    padding: 10px;
    border-bottom: 1px solid #ffffff4c;
  }
  .mobile-inner-links {
    background-color: #ffffff16;
    padding-left: 10px;
  }
  #rajveer-impex {
    display: none;
  }
  #rejveer-mobile {
    display: block;
  }
  .mobile-logo {
    width: 65%;
  }
  .mobile-btn {
    text-align: end;
    width: 30%;
  }
  #blog-post {
    padding-top: 15%;
  }
  #contact-usform {
    padding: 2rem 1rem !important;
  }
  .pagination li {
    margin: 14px 2px;
  }
  .product-inquiry {
    display: block;
  }
  .bg-productinquiry {
    display: none;
  }
  .ball-pulse-sync {
    left: 44%;
  }
  .home-banner {
    height: 20vh !important;
  }
  .loading-anime {
    height: 286px !important;
    margin-top: 12%;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1300px) {
  .navlinks {
    margin-left: 0rem !important;
  }
}
@media screen and (min-width: 1900px) and (max-width: 2500px) {
  .header-banner {
    min-height: 48vh;
  }
  .banner-section {
    margin-top: -65px;
  }
  .banner-content h5 {
    font-size: 35px;
  }
  .banner-content h1 {
    font-size: 80px;
  }
  .banner-content p {
    font-size: 26px;
  }
  .banner-content a {
    font-size: 26px;
  }
  #new-banner {
    margin-top: 11rem !important;
  }
  .bg-about-section {
    margin-top: 2% !important;
  }
  .sidebar-thumb {
    width: 22% !important;
  }
  .sidebar-text {
    padding-left: 45px !important;
  }
  .product-card img {
    height: 301px;
  }
  .home-banner {
    height: 651px;
  }
}
.loading-anime {
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
