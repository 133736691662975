:root {
  --green-color: #85c59d;
  --dark-blue: #252161;
}

.product-main-img{
  position: relative;
}
.product-main-img img {
  max-height: 250px;
}
.small-product-img {
  max-height: 80px;
}
.sale-box{
  padding: 8px 20px;
  font-size: 18px;
  background-color: #4c91b4;
  color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
}

.current-img {
  background-color: #f2f6f7 !important;
}

.product-desc h2 {
  font-size: 35px;
  font-weight: 600;
  color: var(--dark-blue);
}

/* accordian */
.accordian-element {
  box-shadow: 0px 5px 35px 2px rgba(0, 0, 0, 0.06);
  border: 1px solid #d3d3d362;
}

.accordian-element li {
  list-style: none;
}

.accordian-element li label {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 17px;
  font-weight: bold;
  border-bottom: 1px solid #d3d3d362;
  background-color: white;
  cursor: pointer;
  line-height: 22px;
  color: #222;
  display: flex;
  justify-content: space-between;
}

.accordian-element li label::after {
  content: "+";
  font-size: 24px;
}

.accordian-element li input {
  display: none;
}

.accordian-element li .content {
  max-height: 0;
  overflow: hidden;
  padding: 0px 20px;
  transition: max-height 0.3s;
  transition-timing-function: ease-in;
  background-color: #fff;
}

.accordian-element input[type="radio"]:checked+label+.content {
  max-height: 600px;
  padding: 10px 20px;
  border-bottom: 1px solid #d3d3d362;
}

.accordian-element input[type="radio"]:checked+label::after {
  content: "–";
}

.accordian-element input[type="radio"]:checked+label {
  background-color: #c0e4ef;
}

/* inquiry form */
.inquiry-form {
  padding: 30px;
  padding-top: 10px;
  position: fixed;
  width: 32%;
  height: 100%;
  top: 0;
  right: -100%;
  background: #fff;
  box-shadow: 1px 1px 30px #25216162;
  background-size: cover;
  z-index: 1000;
  transition: .6s;
}
.showInquiryForm {
  right: 0;
}

/* product details */
.more-product-detail{
  background-color: #fff;
  padding: 0 50px 80px;
  text-align: center;
}

.more-product-detail div img {
  height: 440px;
}

/* for tab bootstrap */
.nav-item .active{
  color: #4c91b4 !important;
  border: none !important;
  border-bottom: 3px solid #4c91b4 !important;
}
.nav-tabs .nav-link{
  border-radius: 0 !important;
  color: #2b3d4f;
  font-weight: 600;
  padding: 10px 40px 15px !important;
}
.nav-tabs {
  padding-bottom: 1px;
  border-bottom: 1px solid #4c91b4;
}
.no-product{
  font-size: 20px;
  text-align: center;
}